:root {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  padding: 0.5rem;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  display: flex;
  min-height: 98vh;
  flex-direction: column;
}

input,
select:not([multiple]) {
  height: 40px;
}

input,
textarea,
select,
label {
  width: 100%;
  max-width: 420px;
  display: block;
}

input {
  padding: 0.8rem;
}

select {
  -webkit-appearance: button;
  /*webkit browsers */
  -moz-appearance: button;
  /*Firefox */
  appearance: button;
}

.form-row {
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.prism-message {
  margin: 16px 0px 0px 0px;
}
.prism-message i.icon {
  color: #333333;
}
.prism-message__title {
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.prism-message__text--with-title {
  font-family: Roboto;
  color: #333333;
  font-size: 14px;
  line-height: 18px;
}